<template>
  <div v-if="onboarding" class="flex h-full relative">
    <PortalTarget name="side-menu" />

    <div class="flex-1 p-8 md:px-16">
      <PortalTarget name="link-back" />

      <PortalTarget name="mobile-menu" />

      <NuxtPage v-if="onboarding" :onboarding="onboarding" />

      <PortalTarget name="help-center-floating-button" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Onboarding } from '~/services/entities/onboarding.entity'
import { useOnboardingRepository } from '~/composables/repositories'
import useLoading from '~/composables/useLoading'
import { GlobalManufacturersProviderKey } from '~/services/constants'

definePageMeta({
  middleware: 'auth',
})

const { startLoading, stopLoading } = useLoading()
const { params } = useRoute()
const { fetchBySlug } = useOnboardingRepository()

const onboarding = ref<Onboarding | null>(null)

const route = useRoute()

const manufacturerList = computed(() => {
  if (!onboarding.value) {
    return []
  }
  return onboarding.value?.manufacturers.sort((manufacturer1, manufacturer2) => {
    const manufacturer1Name = manufacturer1.modelName
    const manufacturer2Name = manufacturer2.modelName
    return manufacturer1Name > manufacturer2Name ? 1 : -1
  })
})

// const firstManufacturer = computed(() => {
//   if (!onboarding.value) {
//     return null
//   }

//   onboarding.value.onboardingPages.find((page) => page.contents?.find(content => content.type === OnboardingPageContentType.MODEL_GROUP))
// })

provide(GlobalManufacturersProviderKey, manufacturerList)

const _fetchOnboarding = async () => {
  try {
    startLoading()
    const slug = params.slug as string
    const response = await fetchBySlug(slug, !route.query.token)
    onboarding.value = response.data
  } catch (error) {
    throw error
  } finally {
    stopLoading()
  }
}

onMounted(() => {
  _fetchOnboarding()
})
</script>
